<template>
  <div class="character">
    <img src="@assets/images/character.jpg" />
    <div v-if="loading">
      <div class="container result" v-if="flag">
        <h1>您已经提交过啦！测试结果如下</h1>
        <p>姓名：{{result.realname}}</p>
        <p>部门：{{result.dept_name}}</p>
        <p>岗位：{{result.post_name}}</p>
        <p>直接领导人：{{result.sup_name}}</p>
        <p>红色总数：{{result.red_num}}</p>
        <p>蓝色总数：{{result.blue_num}}</p>
        <p>黄色总数：{{result.yellow_num}}</p>
        <p>绿色总数：{{result.green_num}}</p>
        <p>测试时间：{{result.addtime}}</p>
      </div>
      <div class="container" v-else>
        <form>
          <div class="tips">说明：</div>
          <p>
            所有问题都没有好坏和对错之分。请选择让你“最自然的”“最真实的”反应，而不是“最好的”“最应该的”。在最符合你的句子前打钩，每组只选一个答案，做完全部题目后，按提示计算。
          </p>

          <div class="box">
            <span>姓名：</span>
            <input
              type="text"
              maxlength="20"
              v-model="realname"
              placeholder="请输入姓名"
            />
          </div>
          <div class="box">
            <span>部门：</span>
            <input
              type="text"
              maxlength="20"
              v-model="dept_name"
              placeholder="请输入部门"
            />
          </div>
          <div class="box">
            <span>岗位：</span>
            <input
              type="text"
              maxlength="20"
              v-model="post_name"
              placeholder="请输入岗位"
            />
          </div>
          <div class="box">
            <span>直接领导人：</span>
            <input
              type="text"
              maxlength="20"
              v-model="sup_name"
              placeholder="请输入直接领导人"
            />
          </div>

          <div v-show="page === 1">
            <div class="radio-title">1.关于人生观，我的内心其实是：</div>
            <van-radio-group v-model="form.a1">
              <van-radio name="A"><i>A</i>希望能够有尽量多的人生体验，所以会有非常多样化的想法。</van-radio>
              <van-radio name="B"><i>B</i>在小心合理的基础上，谨慎地确定自己的目标，一旦确定会坚定不移地去做。</van-radio>
              <van-radio name="C"><i>C</i>更加注重的是取得一切有可能的成就。</van-radio>
              <van-radio name="D"><i>D</i>宁愿剔除风险而享受平静或现状。</van-radio>
            </van-radio-group>

            <div class="radio-title">2.如果爬山旅游，在下山回来的路线选择上，我更在乎：</div>
            <van-radio-group v-model="form.a2">
              <van-radio name="A"><i>A</i>好玩有趣，所以宁愿新路线回巢。</van-radio>
              <van-radio name="B"><i>B</i>安全稳妥，所以宁愿原路线返回。</van-radio>
              <van-radio name="C"><i>C</i>挑战困难，所以宁愿新路线回巢。</van-radio>
              <van-radio name="D"><i>D</i>方便省心，所以宁愿原路线返回。</van-radio>
            </van-radio-group>

            <div class="radio-title">3.通常在表达一件事情上，我更看重：</div>
            <van-radio-group v-model="form.a3">
              <van-radio name="A"><i>A</i>说话给对方感受到的强烈印象。</van-radio>
              <van-radio name="B"><i>B</i>说话表述的准确程度。</van-radio>
              <van-radio name="C"><i>C</i>说话所能达到的最终目标。</van-radio>
              <van-radio name="D"><i>D</i>说话后周围的人际感受是否舒服。</van-radio>
            </van-radio-group>

            <div class="radio-title">4.在生命的大多数时候，我的内心其实更加欣喜于和希望多些：</div>
            <van-radio-group v-model="form.a4">
              <van-radio name="A"><i>A</i>刺激。</van-radio>
              <van-radio name="B"><i>B</i>安全。</van-radio>
              <van-radio name="C"><i>C</i>挑战。</van-radio>
              <van-radio name="D"><i>D</i>稳定。</van-radio>
            </van-radio-group>

            <div class="radio-title">5.我认为自己在情感上的基本特点是：</div>
            <van-radio-group v-model="form.a5">
              <van-radio name="A"><i>A</i>情绪多变，经常情绪波动。</van-radio>
              <van-radio name="B"><i>B</i>外表上自我抑制能力强，但内心感情起伏极大，一旦挫伤难以平复。</van-radio>
              <van-radio name="C"><i>C</i>感情不拖泥带水，较为直接，只是一旦不稳定，容易激动和发怒。</van-radio>
              <van-radio name="D"><i>D</i>天性情绪四平八稳。</van-radio>
            </van-radio-group>
          </div>

          <div v-show="page === 2">
            <div class="radio-title">6.我认为自己在整个人生中，除了工作以外，在控制欲上面，我：</div>
            <van-radio-group v-model="form.a6">
              <van-radio name="A"><i>A</i>没有控制欲，只有感染带动他人的欲望，但自控能力不算强。</van-radio>
              <van-radio name="B"><i>B</i>用规则来保持我对自己的控制和对他人的要求。</van-radio>
              <van-radio name="C"><i>C</i>内心是有控制欲和希望别人服从我的。</van-radio>
              <van-radio name="D"><i>D</i>不会有任何兴趣去影响别人，也不愿意别人来管控我。</van-radio>
            </van-radio-group>

            <div class="radio-title">7.当与情人交往时，我倾向于着重：</div>
            <van-radio-group v-model="form.a7">
              <van-radio name="A"><i>A</i>兴趣上的相容性，一起做喜欢的事情，对他的爱意溢于言表。</van-radio>
              <van-radio name="B"><i>B</i>思想上的相容性，体贴入微，对他的需求很敏感。</van-radio>
              <van-radio name="C"><i>C</i>智慧上的相容性，沟通重要的想法，客观地讨论辩论事情。</van-radio>
              <van-radio name="D"><i>D</i>和谐上的相容性，包容理解另一半的不同观点。</van-radio>
            </van-radio-group>

            <div class="radio-title">8.在人际交往时，我：</div>
            <van-radio-group v-model="form.a8">
              <van-radio name="A"><i>A</i>心态开放，可以快速建立起友谊和人际关系。</van-radio>
              <van-radio name="B"><i>B</i>非常审慎缓慢地进入，一旦认为是朋友，便长久地维持。</van-radio>
              <van-radio name="C"><i>C</i>希望在人际关系中占据主导地位。</van-radio>
              <van-radio name="D"><i>D</i>顺其自然，不温不火，相对被动。</van-radio>
            </van-radio-group>

            <div class="radio-title">9. 我认为自己大多数时候更是：</div>
            <van-radio-group v-model="form.a9">
              <van-radio name="A"><i>A</i>感情丰富的人。</van-radio>
              <van-radio name="B"><i>B</i>思路清晰的人。</van-radio>
              <van-radio name="C"><i>C</i>办事麻利的人。</van-radio>
              <van-radio name="D"><i>D</i>心态平静的人。</van-radio>
            </van-radio-group>

            <div class="radio-title">10. 通常我完成任务的方式是：</div>
            <van-radio-group v-model="form.a10">
              <van-radio name="A"><i>A</i>经常会赶在最后期限前完成。</van-radio>
              <van-radio name="B"><i>B</i>自己做，精确地做，不要麻烦别人。</van-radio>
              <van-radio name="C"><i>C</i>先做，快速做。</van-radio>
              <van-radio name="D"><i>D</i>使用传统的方法，需要时从他人处得到帮忙。</van-radio>
            </van-radio-group>
          </div>

          <div v-show="page === 3">
            <div class="radio-title">11.如果有人深深惹恼我时，我：</div>
            <van-radio-group v-model="form.a11">
              <van-radio name="A"><i>A</i>内心感到受伤，认为没有原谅的可能，可最终很多时候还是会原谅对方。</van-radio>
              <van-radio name="B"><i>B</i>深深感到愤怒，如此之深怎可忘记？我会牢记，同时未来完全避开那个家伙。</van-radio>
              <van-radio name="C"><i>C</i>会火冒三丈，并且内心期望有机会狠狠地回应打击。</van-radio>
              <van-radio name="D"><i>D</i>我会避免摊牌，因为那还不到那个地步，那个人多行不义必自毙，或者自己再去找新朋友。</van-radio>
            </van-radio-group>

            <div class="radio-title">12.在人际关系中，我最在意的是：</div>
            <van-radio-group v-model="form.a12">
              <van-radio name="A"><i>A</i>得到他人的赞美和欢迎。</van-radio>
              <van-radio name="B"><i>B</i>得到他人的理解和欣赏。</van-radio>
              <van-radio name="C"><i>C</i>得到他人的感激和尊敬。</van-radio>
              <van-radio name="D"><i>D</i>得到他人的尊重和接纳。</van-radio>
            </van-radio-group>

            <div class="radio-title">13. 在工作上，我表现出来更多的是：</div>
            <van-radio-group v-model="form.a13">
              <van-radio name="A"><i>A</i>充满热忱，有很多想法且很有灵性。</van-radio>
              <van-radio name="B"><i>B</i>心思细腻，完美精确，而且为人可靠。</van-radio>
              <van-radio name="C"><i>C</i>坚强而直截了当，而且有推动力。</van-radio>
              <van-radio name="D"><i>D</i>有耐心，适应性强而且善于协调。</van-radio>
            </van-radio-group>

            <div class="radio-title">14. 我过往的老师最有可能对我的评价是：</div>
            <van-radio-group v-model="form.a14">
              <van-radio name="A"><i>A</i>情绪起伏大，善于表达和抒发情感。</van-radio>
              <van-radio name="B"><i>B</i>严格保护自己的私密，有时会显得孤独或是不合群。</van-radio>
              <van-radio name="C"><i>C</i>动作敏捷又独立，并且喜欢自己做事情。</van-radio>
              <van-radio name="D"><i>D</i>看起来安稳轻松，反应度偏低，比较温和。</van-radio>
            </van-radio-group>

            <div class="radio-title">15. 朋友对我的评价最有可能的是：</div>
            <van-radio-group v-model="form.a15">
              <van-radio name="A"><i>A</i>喜欢对朋友述说事情，也有能量说服别人去做事。</van-radio>
              <van-radio name="B"><i>B</i>能够提出很多周全的问题，而且需要许多精细的解说。</van-radio>
              <van-radio name="C"><i>C</i>愿意直言表达想法，有时会直率而犀利地谈论不喜欢的人、事、物。</van-radio>
              <van-radio name="D"><i>D</i>通常与他人一起是多听少说。</van-radio>
            </van-radio-group>
          </div>

          <div v-show="page === 4">
            <div class="radio-title">16. 在帮助他人的问题上，我倾向于：</div>
            <van-radio-group v-model="form.a16">
              <van-radio name="A"><i>A</i>多一事不如少一事，但若他来找我，那我定会帮他。</van-radio>
              <van-radio name="B"><i>B</i>值得帮助的人应该帮助，锦上添花犹胜雪中送炭。</van-radio>
              <van-radio name="C"><i>C</i>无关者何必要帮，但我若承诺，必欲完之而后释然。</van-radio>
              <van-radio name="D"><i>D</i>虽无英雄打虎之胆，却有自告奋勇之心。</van-radio>
            </van-radio-group>

            <div class="radio-title">17. 面对他人对自己的赞美，我的本能反应是：</div>
            <van-radio-group v-model="form.a17">
              <van-radio name="A"><i>A</i>没有也无所谓，特别欣喜那也不至于。</van-radio>
              <van-radio name="B"><i>B</i>我不需要那些无关痛痒的赞美，宁可他们欣赏我的能力。</van-radio>
              <van-radio name="C"><i>C</i>有点怀疑对方是否认真或者立即回避众人的关注。</van-radio>
              <van-radio name="D"><i>D</i>赞美总是一件令人心情非常愉悦的事。</van-radio>
            </van-radio-group>

            <div class="radio-title">18. 面对生活的现状，我的行为习惯更加倾向于：</div>
            <van-radio-group v-model="form.a18">
              <van-radio name="A"><i>A</i>外面怎么变化与我无关，我觉得自己这样还不错。</van-radio>
              <van-radio name="B"><i>B</i>这个世界如果我没什么进步，别人就会进步，所以我需要不停地前进。</van-radio>
              <van-radio name="C"><i>C</i>在所有的问题未发生之前，就应该尽量想好所有的可能性。</van-radio>
              <van-radio name="D"><i>D</i>每天的生活开心快乐最重要。</van-radio>
            </van-radio-group>

            <div class="radio-title">19. 对于规则，我内心的态度是：</div>
            <van-radio-group v-model="form.a19">
              <van-radio name="A"><i>A</i>不愿违反规则，但可能因为松散而无法达到规则的要求。</van-radio>
              <van-radio name="B"><i>B</i>打破规则，希望由自己来制定规则而不是遵守规则。</van-radio>
              <van-radio name="C"><i>C</i>严格遵守规则，并且竭尽全力做到规则内的最好。</van-radio>
              <van-radio name="D"><i>D</i>不喜被规则束缚，不按规则出牌会觉得新鲜有趣。</van-radio>
            </van-radio-group>

            <div class="radio-title">20. 我认为自己在行为上的基本特点是：</div>
            <van-radio-group v-model="form.a20">
              <van-radio name="A"><i>A</i>慢条斯理，办事按部就班，能与周围的人协调一致。</van-radio>
              <van-radio name="B"><i>B</i>目标明确，集中精力为实现目标而努力，善于抓住核心要点。</van-radio>
              <van-radio name="C"><i>C</i>慎重小心，为做好预防及善后，会不惜一切而尽心操劳。</van-radio>
              <van-radio name="D"><i>D</i>丰富跃动，不喜欢制度和约束，倾向于快速反应。</van-radio>
            </van-radio-group>
          </div>

          <div v-show="page === 5">
            <div class="radio-title">21. 在面对压力时，我比较倾向于选用：</div>
            <van-radio-group v-model="form.a21">
              <van-radio name="A"><i>A</i>眼不见为净地化解压力。</van-radio>
              <van-radio name="B"><i>B</i>压力越大抵抗力越大。</van-radio>
              <van-radio name="C"><i>C</i>和别人讲也不一定有用，压力在自己的内心慢慢地咀嚼。</van-radio>
              <van-radio name="D"><i>D</i>本能地回避压力，回避不掉就用各种方法来宣泄出去。</van-radio>
            </van-radio-group>

            <div class="radio-title">22. 当结束一段刻骨铭心的感情时，我会：</div>
            <van-radio-group v-model="form.a22">
              <van-radio name="A"><i>A</i>非常难受，可是日子总还是要过的，时间会冲淡一切的。</van-radio>
              <van-radio name="B"><i>B</i>虽然觉得受伤，但一旦下定决心，就会努力把过去的影子摔掉。</van-radio>
              <van-radio name="C"><i>C</i>深陷在悲伤的情绪中，在相当长的时期里难以自拔，也不愿再接受新的人。</van-radio>
              <van-radio name="D"><i>D</i>痛不欲生，需要找朋友倾诉或者找渠道发泄，寻求化解之道。</van-radio>
            </van-radio-group>

            <div class="radio-title">23. 面对他人的倾诉，我回顾自己大多时候本能上倾向于：</div>
            <van-radio-group v-model="form.a23">
              <van-radio name="A"><i>A</i>认同并理解对方感受。</van-radio>
              <van-radio name="B"><i>B</i>做出一些定论或判断。</van-radio>
              <van-radio name="C"><i>C</i>给予一些分析或推理。</van-radio>
              <van-radio name="D"><i>D</i>发表一些评论或意见。</van-radio>
            </van-radio-group>

            <div class="radio-title">24. 我在以下哪个群体中较感满足？</div>
            <van-radio-group v-model="form.a24">
              <van-radio name="A"><i>A</i>能心平气和最终大家达成一致结论的。</van-radio>
              <van-radio name="B"><i>B</i>能彼此展开充分激烈的辩论。</van-radio>
              <van-radio name="C"><i>C</i>能详细讨论事情的好坏和影响的。</van-radio>
              <van-radio name="D"><i>D</i>能随意无拘束地自由散谈，同时又很开心。</van-radio>
            </van-radio-group>

            <div class="radio-title">25. 在内心的真实想法里，我觉得工作：</div>
            <van-radio-group v-model="form.a25">
              <van-radio name="A"><i>A</i>如果不必有压力，可以让我做我熟悉的工作那就不错。</van-radio>
              <van-radio name="B"><i>B</i>应该以最快的速度完成，且争取去完成更多的任务。</van-radio>
              <van-radio name="C"><i>C</i>要么不做，要做就做到最好。</van-radio>
              <van-radio name="D"><i>D</i>如果能将乐趣融合在里面那就太棒了，不过如果不喜欢的工作实在没劲。</van-radio>
            </van-radio-group>
          </div>

          <div v-show="page === 6">
            <div class="radio-title">26. 如果我是领导，我内心更希望在部属心目中，我是：</div>
            <van-radio-group v-model="form.a26">
              <van-radio name="A"><i>A</i>可以亲近的和善于为他们着想的。</van-radio>
              <van-radio name="B"><i>B</i>有很强的能力和富有领导力的。</van-radio>
              <van-radio name="C"><i>C</i>公平公正且足以信赖的。</van-radio>
              <van-radio name="D"><i>D</i>被他们喜欢并且觉得富有感召力的。</van-radio>
            </van-radio-group>

            <div class="radio-title">27. 我希望得到的认同方式是：</div>
            <van-radio-group v-model="form.a27">
              <van-radio name="A"><i>A</i>无所谓别人是否认同。</van-radio>
              <van-radio name="B"><i>B</i>精英群体的认同最重要。</van-radio>
              <van-radio name="C"><i>C</i>只要我认同的人或者我在乎的人的认同就可以了。</van-radio>
              <van-radio name="D"><i>D</i>希望得到所有大众的认同。</van-radio>
            </van-radio-group>

            <div class="radio-title">28. 当我还是个孩子的时候，我：</div>
            <van-radio-group v-model="form.a28">
              <van-radio name="A"><i>A</i>不太会积极尝试新事物，通常比较喜欢旧有的和熟悉的。</van-radio>
              <van-radio name="B"><i>B</i>是孩子王，大家经常听我的决定。</van-radio>
              <van-radio name="C"><i>C</i>害羞见生人，有意识地回避。</van-radio>
              <van-radio name="D"><i>D</i>调皮可爱，在大部分的情况下是乐观而又热心的。</van-radio>
            </van-radio-group>

            <div class="radio-title">29. 如果我是父母，我也许是：</div>
            <van-radio-group v-model="form.a29">
              <van-radio name="A"><i>A</i>不愿干涉子女或者容易被说动的。</van-radio>
              <van-radio name="B"><i>B</i>严厉的或者直接给予方向性指点的。</van-radio>
              <van-radio name="C"><i>C</i>用行动代替语言来表示关爱或者高要求的。</van-radio>
              <van-radio name="D"><i>D</i>愿意陪伴孩子一起玩的，孩子的朋友们所喜欢和欢迎的。</van-radio>
            </van-radio-group>

            <div class="radio-title">30. 以下有四组格言，哪组里符合我感觉的数目最多?</div>
            <van-radio-group v-model="form.a30">
              <van-radio name="A"><i>A</i>最深刻的真理是最简单和最平凡的。
                要在人世间取得成功必须大智若愚。
                好脾气是一个人在社交中所能穿着的最佳服饰。
                知足是人生在世最大的幸福。
              </van-radio>
              <van-radio name="B"><i>B</i>
                走自己的路，让人家去说吧。
                虽然世界充满了苦难，但是苦难总是能战胜的。
                有所成就是人生唯一的真正的乐趣。
                对我而言解决一个问题和享受一个假期一样好。
              </van-radio>
              <van-radio name="C"><i>C</i>
                一个不注意小事情的人，永远不会成功大事业。
                理性是灵魂中最高贵的因素。
                切忌浮夸铺张。与其说得过分，不如说得不全。
                谨慎比大胆要有力量得多。
              </van-radio>
              <van-radio name="D"><i>D</i>
                与其在死的时候握着一大把钱，还不如活时活得丰富多彩。
                任何时候都要最真实地对待你自己，这比什么都重要。
                使生活变成幻想，再把幻想化为现实。
                幸福不在于拥有金钱，而在于获得成就时的喜悦以及产生创造力的激情。
              </van-radio>
            </van-radio-group>
          </div>

          <div style="height: .3rem"></div>

          <div v-if="page == 6">
            <div class="submit" @click="submitCharacter">提交问卷</div>
          </div>
          
          <div class="btn">
            <div class="page" @click="next" v-show="page != 6">下一页</div>
            <div class="page prev" @click="prev" v-show="page != 1">上一页</div>
            <div class="text">当前第<span>{{page}}</span>页&nbsp;共<span>6</span>页</div>
          </div>
          
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { MessageBox } from 'mint-ui';
import { RadioGroup, Radio } from "vant";
import { characterSelect, characterSave } from "@api/user";
Vue.use(Radio);
Vue.use(RadioGroup);
export default {
  data() {
    return {
      page: 1,
      realname : '',
      post_name: '',
      dept_name: '',
      sup_name: '',
      form: {
        a1: "",
        a2: "",
        a3: "",
        a4: "",
        a5: "",
        a6: "",
        a7: "",
        a8: "",
        a9: "",
        a10: "",
        a11: "",
        a12: "",
        a13: "",
        a14: "",
        a15: "",
        a16: "",
        a17: "",
        a18: "",
        a19: "",
        a20: "",
        a21: "",
        a22: "",
        a23: "",
        a24: "",
        a25: "",
        a26: "",
        a27: "",
        a28: "",
        a29: "",
        a30: ""
      },
      flag: false,
      loading: false,
      result: {}
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.initEvent();
  },
  methods: {
    // 初始化事件
    initEvent() {
      let that = this
      characterSelect().then(res => {
        if(res.data) {
          that.result = res.data
          that.flag = true
        } else {
          that.flag = false
        }
        that.loading = true
      }).catch(err => {
        that.flag = false
        that.loading = true
        that.$dialog.error(err.msg)
      })
    },

    // 上一页
    prev() {
      let that = this
      if(that.page > 1 && that.page < 7) {
        that.page--
      }
    },

    // 下一页
    next() {
      let that = this,
        page = that.page,
        form = that.form,
        n = (page-1)*5 + 1
      
      for(let i=n; i< n+5; i++) {
        if(!form['a'+i]) {
          that.$dialog.error("请填写完当前页所有的选项！");
          return
        }
      }      

      if(page > 0 && page < 6) {
        that.page++
      }

    },

    // 计算选项出现的次数
    getEleNums(n,m,data) {
      let arr = {a:0, b:0, c:0, d:0}
      for (let i = n; i < m; i++) {
        if(data[i] == 'A') {
          arr.a++
        } else if (data[i] == 'B') {
          arr.b ++
        } else if (data[i] == 'C') {
          arr.c ++
        } else {
          arr.d ++
        }
      }

      return [arr.a, arr.b, arr.c, arr.d]
    },

    submitCharacter() {
      let that = this;
      
      // 必填提示
      if (!that.realname) {
        that.$dialog.error("姓名必填");
        return;
      }
      if (!that.dept_name) {
        that.$dialog.error("部门必填");
        return;
      }
      if (!that.post_name) {
        that.$dialog.error("岗位必填");
        return;
      }
      if (!that.sup_name) {
        that.$dialog.error("直接领导人必填");
        return;
      }
      for(let i=1; i< 31; i++) {
        if(!that.form['a'+i]) {
          that.$dialog.error("选项"+i+"尚没有选择！");
          return
        }
      } 

      let form = [];
      for(let i in that.form) {
        form.push(that.form[i])
      } 
      let total_one = that.getEleNums(0, 15, form)
      let total_two = that.getEleNums(15, 30, form)
      let params = {
        realname: that.realname,
        dept_name: that.dept_name,
        post_name: that.post_name,
        sup_name: that.sup_name,
        total_one: total_one,
        total_two: total_two,
        answer: form,
        red_num: total_one[0]+total_two[3],
        blue_num: total_one[1]+total_two[2],
        yellow_num: total_one[2]+total_two[1],
        green_num: total_one[3]+total_two[0]
      }; 

      MessageBox({
        title: "提示",
        message: '提交之后所有信息不可修改，确定提交？',
        showCancelButton: true,
      }).then((status) => {
        if (status === "confirm") {
          characterSave(params).then(() => {
            that.flag = true
            that.$router.go(0)
          }).catch((err) => {
            that.flag = false;
            that.$dialog.error(err.msg);
          });
        } else {
          return;
        }
      });
      
    },
  },
};
</script>

<style scoped lang="scss">
.character {
  padding: 0.2rem;
  background: linear-gradient(#fff, #62E3CD);
  color: black;
  img {
    width: 100%;
    height: auto;
  }
  .result {
    h1 {
      font-size: .28rem;
      color: #3BC4AA;
      text-align: center;
      line-height: 1rem;;
    }
  }
  .container {
    background: white;
    font-size: 0.26rem;
    padding: 0.2rem;
    .box {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      height: 0.72rem;
      line-height: 0.72rem;
      span {
        width: 25%;
      }
      input {
        width: 70%;
        text-align: right;
        border-bottom: 1px solid #000;
      }
    }
    .tips {
      color: #3BC4AA;
      border-bottom: 1px dashed #3BC4AA;
      font-size: 0.32rem;
      line-height: 0.72rem;
    }
    p {
      text-indent: 2em;
      line-height: 0.4rem;
      margin: 0.2rem 0 0.3rem 0;
    }
    .van-radio-group {
      font-size: .26rem;
      .van-radio {
        margin: .2rem 0;
        i {
          font-size: .28rem;
          margin-right: .1rem;
        }
      }
    }
    .radio-title {
      margin: 0.5rem 0 0.2rem 0;
      font-weight: 700;
      font-size: .26rem;
    }
    .btn {
      margin: .2rem 0 .5rem;
      .text {
        line-height: .6rem;
        text-align: right;
        span {
          color: #3BC4AA;
        }
      }
      .page {
        color: white;
        background: #3BC4AA;
        padding: 0 .1rem;
        background: #3BC4AA;
        border-radius: 8px;
        height: .8rem;
        line-height: .8rem;
        text-align: center;
        font-size: .3rem;
        margin-top: .2rem;
      }
      .prev {
        background: transparent;
        border: 1px solid #3BC4AA;
        color: #3BC4AA;
      }
    }
    textarea {
      border: 1px solid #000;
      border-radius: 0.12rem;
      width: 100%;
      height: 2rem;
      padding: 0.2rem;
    }
    .submit {
      display: block;
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
      margin-bottom: 0;
      font-size: 0.32rem;
      background: #3BC4AA;
      text-align: center;
      border-radius: 0.12rem;
      color: white;
      margin-top: .5rem;
    }
    h4 {
      margin: 0.2rem 0;
      font-size: 0.32rem;
      text-align: center;
      font-weight: 500;
    }
  }
}
</style>